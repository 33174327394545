$(function () {

// Smooth scroll
  $('a[href^="#"]').click(function () {
    var speed = 400;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $('body,html').animate({
      scrollTop: position
    }, speed, 'swing');
    return false;
  });

  // 一定距離スクロール後にトップへ戻るボタンを表示
  var limit = 400;
  var $scroll_up_icon = $('.l-pagetop');
  $(window).on('load scroll', function () {
    if ($(this).scrollTop() > limit) {
      $scroll_up_icon.fadeIn()
    } else {
      $scroll_up_icon.fadeOut()
    }
  });

  // Parallax
  var target1 = $(".p-parallax--primary");
  var targetPosOT1 = target1.offset().top;
  var target2 = $(".p-parallax--secondary");
  var targetPosOT2 = target2.offset().top;
  var targetFactor = 0.5;
  var windowH = $(window).height();
  var scrollYStart1 = targetPosOT1 - windowH;
  var scrollYStart2 = targetPosOT2 - windowH;
  $(window).on('scroll',function(){
    var scrollY = $(this).scrollTop();
    if(scrollY > scrollYStart1){
      target1.css('background-position-y', (scrollY - targetPosOT1) * targetFactor + 'px');
    }else{
      target1.css('background-position','center top');
    }
    if(scrollY > scrollYStart2){
      target2.css('background-position-y', (scrollY - targetPosOT2) * targetFactor + 'px');
    }else{
      target2.css('background-position','center -333px');
    }
  });

  // inview
  $('.c-inview').on('inview', function(e, isInView){
    if (isInView) {
      $(this).addClass('is-inview');
    }
  });

});
