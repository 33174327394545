function initMap() {
  var mapPosition = {lat: 35.173659, lng: 136.897905};
  var mapArea = document.getElementById('googlemap');
  var mapOptions = {
    center: mapPosition,
    zoom: 17,
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    zoomControl: true,
  };
  var map = new google.maps.Map(mapArea, mapOptions);

  var markerOptions = {
    map: map,
    position: mapPosition,
    icon: './img/map-pin.svg'
  };
  var marker = new google.maps.Marker(markerOptions);

  var mapStyle = [ {
    "stylers": [ {
      "saturation": -100
    } ]
  } ];
  var mapType = new google.maps.StyledMapType(mapStyle);
  map.mapTypes.set( 'GrayScaleMap', mapType);
  map.setMapTypeId( 'GrayScaleMap' );
}
window.onload = function () {
  initMap();
};